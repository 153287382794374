.PR-loginTitle {
    display: flex;
    justify-content: center;
    margin-top: 100px;
    width: 100%;
}

.PR-form-wrapper {
    width: 100%;
    display: flex;
    height: 500px;
    flex-direction: column;
    align-items: center;
}

.PR-textfield-wrapper {
    width: 300px;
    margin-top: 10px;
}

.PR-buttons-wrapper {
    width: 300px;
    display: flex;
    margin-top: 30px;
    justify-content: space-between;
}

.PR-success-alert {
    width: 300px;
    background-color: #008000cc;
    padding: 15px;
    border: solid;
    border-radius: 8px;
    color: white;
    margin: 10px;
}

.PR-error-alert {
    width: 300px;
    background-color: #FD397A;
    padding: 15px;
    border: solid;
    border-radius: 8px;
    color: white;
    margin: 10px;
}
