 .container-map-delete-modal-location-list {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.container-map-view-modal-location-list {
  min-height: 400px;
  width: 100%;
}

.container-delete-modal-location-list {
  display: flex;
  justify-content: flex-end;
}

.modal-location {
  display: flex;
  text-align: center;

  &__list-field {
    margin-top: 10px;
    padding: 8px;
    width: 100%;
    height: 100%;
  }
}
