.__container-reports {
    display: flex;
    min-height: 500px;
    width: 100%;
}

.__container-reports-panel {
    min-height: 400px;
    width: 100%;
}

.__container-reports-form-checkbox {
    display: flex;
    justify-content: space-around;
    margin-top: 40px;
    width: 100%;
}

.__container-reports-form {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.__container-checkbox-days {
    align-items: center;
    display: flex;
    flex-direction: column;
    justify-content: center;
    width: 50%;
}

.reports-days {
    width: 295px;
}

.__container-reports-message {
    margin: 60px 0 0 0;
    padding: 0 20px;
    width: 100%;
}
