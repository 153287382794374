.custom-fields-wrapper {
  width: 100%;
  display: flex;
  flex-direction: column;
  &__tab-area {
    padding: 15px;
    // background-color: red;
    height: 300px;
    &__header {
    }
    &__content {
    }
  }
}

.custom-controls-column {
  width: 20%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
}

.custom-controls-icon {
  height: 100%;
  display: flex;
  padding: 3px;
  justify-items: center;
  align-items: center;
  margin-left: 5%;
  margin-right: 5%;
}

.custom-controls-wrapper {
  width: 100%;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  column-gap: 10px;
  overflow-x: auto;

  &__element {
    display: flex;
    flex-flow: row wrap;
    align-items: center;
    background-color: white;
    &:hover {
      background-color: #efefef;
    }
    width: 160px;
    height: 50px;
    color: #5b5b5b;
    padding: 5px;
    font-weight: bold;
    cursor: grab;
    margin: 7px 0px;
    border: 1px;
    border-style: solid;
    border-color: #e0e0e0;
    border-radius: 8px;
  }

  &__element:active {
    cursor: grabbing;
  }
}

.tab-properties-wrapper {
  width: 100%;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-evenly;

  &__tab-name {
    align-items: center;
    max-width: 350px;
    display: flex;
    flex-direction: row;
  }

  &__tab-layout {
    display: flex;
    flex-direction: row !important;
    flex-wrap: unset !important;
  }

  &__update-tab-name {
    width: 10px;
  }
}

.MuiDialog-paperWidthSm {
  max-width: unset !important;
}

.editor-wrapper {
  padding: 2px;
  border: 1px solid #e8e8e8;
  background-color: white;
}

.editor-general-container {
  display: flex;
  flex-direction: column;
  justify-items: flex-start;
}

.drag-drop-custom-fields-wrapper {
  display: flex;
  text-align: center;

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 400px;
    margin: 10px;
    border: 2px dashed #707b7c;
  }

  &__legend {
    padding-top: 10px;
    color: #424949;
  }
}

.field-properties-wrapper {
  width: 100%;
}

.custom-field-help-tooltip {
  max-width: 300px;

  &_text {
    white-space: pre-line;
    color: red;
    font-size: 14;
  }

  &__icon {
    font-size: 2rem !important;
    color: #74726a;
    margin-left: 5px;
  }
}
