.logo-container {
  display: flex;
  justify-content: center;
  align-items: center;
  min-height: 200px;

  &__logo {
    max-width: 514px;
    max-height: 200px;
  }
}