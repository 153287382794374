$color-dividers: #dbdbdb;

.settings-design {
  @media (max-width: 960px) {
    flex-direction: column;
  }

  &__content {
    display: 'flex';
    flex-direction: 'row';

    &__fields {
      align-self: center;
      align-items: center;
      display: flex;
      flex-direction: column;
      min-width: 33%;
      min-height: '200px';

      &__col {
        align-items: center;
        display: flex;
        flex-direction: column;
        width: 100%;
      }

      @media (max-width: 600px) {
        flex-direction: column;
        width: 100%;
        margin: 20px 0px;
      }

      @media (min-width: 600px) and (max-width: 960px) {
        flex-direction: row;
        width: 100%;
        margin: 20px 0px;
      }
    }

    &__col {
      align-items: center;
      display: flex;
      flex-direction: column;
      min-width: 33%;
      min-height: '200px';

      @media (max-width: 600px) {
        flex-direction: column;
        width: 100%;
      }

      @media (min-width: 600px) and (max-width: 960px) {
        flex-direction: row;
        width: 100%;
      }

      &__image-upload {
        display: flex;
        flex: 2;
      }

      &__fields {
        align-items: center;
        display: flex;
        flex-direction: column;
        flex: 1;
      }
    }
  }
}

.mainSettingsDiv {
  display: flex;
  @media screen and (max-width: 900px) {
    display: flex;
    flex-direction: column;
  }
}

.settingDivider {
  width: 50%;
  display: flex; 
  flex-direction: column; 
  align-items: center; 
  justify-content:space-around;
  
  @media screen and (max-width: 900px) {
    width: 100%;
  }
}

.colorPickerDivider {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-around;
  border: 1px solid $color-dividers;
  border-radius: 16px;
  padding: 10px;
  width: 100%;
  margin: 10px;
}

.colorPickerTitle {
    position: absolute;
    color: grey;
}
