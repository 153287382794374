.__container-policies-tab {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.__container-policies-tabs {
  padding-left: 5px;
}

.__container-policies-general-fields {
  width: 100%;
}

.__container-message-notification-api {
  width: 100%;
}

.__container-general-panel {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-bottom: 15px;
  padding: 0px 10px 20px 10px;
  div {
    margin-top: 10px;
  }
}

.__container-baseandcustom-panel {
  max-height: 300px;
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
}

.__container-baseandcustom-panel::-webkit-scrollbar {
  -webkit-appearance: none;
}

.__container-baseandcustom-panel::-webkit-scrollbar:vertical {
  width: 10px;
}

.__container-baseandcustom-panel::-webkit-scrollbar-button:increment,
.__container-baseandcustom-panel::-webkit-scrollbar-button {
  display: none;
}

.__container-baseandcustom-panel::-webkit-scrollbar:horizontal {
  height: 10px;
}

.__container-baseandcustom-panel::-webkit-scrollbar-thumb {
  background-color: #797979;
  border: 2px solid #f1f2f3;
  border-radius: 20px;
}

.__container-baseandcustom-panel::-webkit-scrollbar-track {
  border-radius: 10px;
}

.__container-basefield,
.__container-customfield {
  display: flex;
  flex-direction: column;
  margin-left: 10px;
}

.__container-sendmessage-panel,
.__container-sendnotification-panel,
.__container-send-api {
  min-height: 400px;
  width: 100%;
}

.__container-on-load {
  display: flex;
  flex-direction: column;
  min-height: 400px;
  padding: 10px 15px;
  width: 100%;
}

.__container-form {
  width: 80%;
}

.__container-form-checkbox {
  display: flex;
  flex-direction: column-reverse;
  justify-content: space-around;
  margin-top: 10px;
  width: 100%;
  align-items: center;
}

.__container-checkbox {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: center;
  margin-top: 20px;
}

.__container-policies-message {
  padding: 0 20px;
  width: 100%;
  margin-top: 20px;
}

.__container-message-multiline,
.__container-post {
  padding: 0 20px;
}

.__container-checkbox-notification {
  align-items: center;
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  margin-top: 10px;
  margin-bottom: 20px;
  width: 100%;
  label {
    flex-direction: column-reverse;
  }
}

.__box-icons {
  display: flex;
  flex-wrap: wrap;
  width: 135px;
}

.iconSelected {
  margin: 10px 0;
}

.notification-icons {
  margin: 10px;
}

.__container-url-disabled {
  display: flex;
  justify-content: flex-start;
  margin-top: 40px;
}

.__container-url {
  align-items: center;
  display: flex;
  padding: 0 20px;
  width: 100%;
}

.__container-disabled {
  padding: 50px 0 0 0.4rem;
  width: 25%;
}

.url_textField,
.token_textField {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  width: 90%;
}

.__token-on-load-container {
  align-items: baseline;
  display: flex;
  flex-direction: row;
  margin-bottom: 25px;
}

.__base-fields-accordion {
  display: flex;
  &__no-info {
    align-items: center;
    display: flex;
    font-weight: 700;
    font-size: 1.1rem;
    justify-content: center;
    height: 80px;
    width: 100%;
    text-align: center;
  }
}

.__on-load--references-tabs {
  margin-left: 0px;
}

.__on-field-container {
  width: 100%;
  padding: 20px;
}

.__on-field-date-cycle-container {
  align-items: flex-start;
  display: flex;
  flex-direction: column-reverse;
}

.__rules {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.__on-field-date-cycle-checkboxes {
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  label {
    margin-left: 0px;
  }
}

.__date-cycle-rule {
  align-items: flex-end;
  display: flex;
  flex-direction: row;
  margin-bottom: 20px;
}

.__rule-three {
  align-items: flex-start;
  flex-direction: column;
  p {
    font-size: 1.2rem;
    margin-top: 20px;
    margin-bottom: 20px;
  }
}

.__on-field-result-tabs {
  margin-bottom: 10px;
}

@media (max-width: 600px) {
  .url_textField {
    flex-direction: column-reverse;
    label {
      margin: 0px;
    }
  }
  .token_textField {
    flex-direction: column;
    div {
      margin: 0px;
    }
    label {
      margin: 0px;
    }
  }
  .__container-post {
    padding: 0px 0px 0px 20px;
  }
  .__container-send-api {
    margin-top: 10px;
  }
}

@media (min-width: 600px) {
  .__container-general-panel {
    padding: 0px;
    margin-bottom: 40px;
  }
  .__container-policies-tabs {
    padding: 0px 20px;
  }
  .__container-policies-tab {
    flex-direction: row;
  }
  .__container-policies-general-fields {
    margin-right: 20px;
    min-height: 500px;
    width: 25%;
  }
  .__container-message-notification-api {
    width: 75%;
  }
  .__container-form-checkbox {
    flex-direction: row;
  }
  .__container-checkbox {
    align-items: flex-end;
    flex-direction: column;
    margin-top: 0px;
  }
  .__container-policies-message {
    margin-top: 60px;
  }
  .__container-form {
    width: auto;
  }
  .__container-checkbox-notification {
    align-items: flex-end;
    flex-direction: column;
    margin-top: 0px;
    margin-bottom: 0px;
    width: auto;
    label {
      flex-direction: row-reverse;
    }
  }
  .__on-load--references-tabs {
    margin-left: 20px;
  }
  .__on-field-container {
    padding: 0px;
  }
  .__on-field-date-cycle-container {
    align-items: flex-start;
    display: flex;
    flex-direction: row;
    padding-right: 60px;
  }
  .__rules {
    margin-left: 20px;
  }
  .__on-field-date-cycle-checkboxes {
    align-items: flex-end;
    label {
      margin-left: 16px;
    }
  }
  .__rule-three {
    align-items: flex-end;
    flex-direction: row;
    p {
      margin: 0px 8px;
    }
  }
  .__on-field-result-tabs {
    margin-left: 40px;
  }
}
