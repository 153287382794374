.profile-tab-wrapper {
  flex-direction: column;
  &__picture-wrapper {
    position: relative;
  }

  &__picture-delete {
    position: absolute !important;
    top: 0;
    right: 0;
  }

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__picture-placeholder {
    width: 300px;
    height: 300px;
  }
}

@media (min-width: 600px) {
  .profile-tab-wrapper {
    flex-direction: row;
  }
}