.image-upload-wrapper {
  &__picture-wrapper {
    display: flex;
    justify-content: center;
    position: relative;
    width: -webkit-fill-available;
    margin-bottom: 15px;
  }

  &__picture-title {
    margin-bottom: 35px;
  }

  &__picture-delete {
    position: absolute !important;
    top: 0;
    right: 5%;
  }

  &__picture {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__picture-placeholder {
    height: 300px;
    width: 90%;
  }
}