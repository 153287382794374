.profile-tab-wrapper {
  display: flex;
  justify-content: space-evenly;
  margin-top: 30px;

  &__picture {
    
  }

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
    @media screen and (max-width: 600px) {
      margin-top: 20px;
    }
  }
}