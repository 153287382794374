.__title {
  display: flex;
  flex-direction: column;
  &__buttons {
    display: flex;
    flex-direction: row;
    margin-top: 15px;
    flex-wrap: wrap;
    justify-content: center;
  }
}

.__custom-fields {
  display: flex;
  justify-content: flex-start;
  flex-direction: column;
  flex-wrap: wrap;
  margin: 30px 30px 0px 30px;
  width: 100%;
}

.__locations-filter {
  flex-wrap: nowrap;
  max-height: 350px;
  overflow-x: auto;
  overflow-y: auto;
  h4 {
    margin-bottom: 10px;
  }
}

@media (min-width: 600px) {
  .__title {
    align-items: center;
    flex-direction: row;
    justify-content: space-between;
    &__buttons {
      margin-top: 0px;
    }
  }
}
