.locations-list {
  // background-color: red;
  display: flex;
  @media screen and (max-width: 700px) {
    flex-direction: column;
  }

  &__no-locations {
    margin: 10px 0px !important;
    text-align: center;
  }

  &__top {
    width: 100%;
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
  }

  &__top-add {
  }

  &__left-content {
    min-width: 20%;
    @media screen and (max-width: 1000px) and (min-width: 700px) {
      min-width: 25%;
    }
    @media screen and (max-width: 700px) {
      min-width: 100%;
    }
    // background-color: yellow;
  }

  &__right-content {
    min-width: 80%;
    @media screen and (max-width: 1000px) and (min-width: 700px) {
      min-width: 75%;
    }
    @media screen and (max-width: 700px) {
      min-width: 100%;
    }
    // background-color: #FAFAFA;
  }

  &__map-view {
    width: 30%;
    height: 500px;
    margin-top: -25px;
    margin-left: -25px;
  }
}

.locations {
  margin-bottom: 20px;
  overflow: auto;
}

.circular-progress-indicator {
  width: 100%;
  display: flex;
  justify-content: center;
  margin-bottom: 30px;
}

::-webkit-scrollbar {
  -webkit-appearance: none;
}

::-webkit-scrollbar:vertical {
  width: 10px;
}

::-webkit-scrollbar-button:increment,
::-webkit-scrollbar-button {
  display: none;
}

::-webkit-scrollbar:horizontal {
  height: 10px;
}

::-webkit-scrollbar-thumb {
  background-color: #a3a3a3;
  border: 2px solid #f1f2f3;
  border-radius: 20px;
}

::-webkit-scrollbar-track {
  border-radius: 10px;
}
