.drag-drop-process {
    display: flex;
    text-align: center;
    margin-top: 30px;

    &__container {
      background-color: white;
      display: flex;
      flex-direction: column;
      width: 98%;
      margin: 10px;
    }

    &__container::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &__container::-webkit-scrollbar:vertical {
      width: 10px;
    }

    &__container::-webkit-scrollbar-button:increment,
    &__container::-webkit-scrollbar-button {
      display: none;
    }

    &__container::-webkit-scrollbar:horizontal {
      height: 10px;
    }

    &__container::-webkit-scrollbar-thumb {
      background-color: #797979;
      border: 2px solid #f1f2f3;
      border-radius: 20px;
    }

    &__container::-webkit-scrollbar-track {
      border-radius: 10px;
    }

    &__legend {
      padding-top: 10px;
      color: white;
      text-align: center;
    }

    &__list-field {
      // padding: 8px;
      margin-top: 10px;
      margin-bottom: 5px;
      width: 50px;
      height: 100%;
      display: 'flex';
      flex-direction: row;
      padding: 0;
    }
    &__single-custom-field {
      margin: 8px;
    }
  }

.dndItem {
  width: 120px;
  margin: 5px;
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 5px;
  justify-content: space-around;
  border: 1px solid #707B7C;
  border-radius: 26px;
}

.dndItemArrow {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}
