.drag-drop-area {
  display: flex;
  text-align: center;

  &__column {
    display: flex;
    flex-direction: column;
    width: 100%;
    margin: 10px;
    border: 1.5px dashed #707B7C;
    border-radius: 10px;

    @media (max-width: 600px) {
      margin: 10 0px;
    }
  }

  &__legend {
    padding-top: 10px;
    color: #424949;
  }

  &__list-field {
    margin-top: 10px;
    // padding: 8px;
    width: 100%;
    height: 100%;
  }

  &__single-custom-field {
    margin: 8px;
    // margin-bottom: 8px;
    // background-color: red;
    // border: 1px solid yellow;
    // width: 100%;
  }
}