.drag-drop-process {
  display: flex;
  text-align: center;
  margin-top: 30px;
  
  &__content {
    background-color: ghostwhite;
    display: flex;
    flex-direction: column;
    min-width: 200px;
    min-height: 380px;
    // width: 100%;
    // min-height: 400px;
    margin: 10px;
    border: 1px solid #707B7C;
    border-radius: 10px;
  }

  &__legend {
    padding-top: 10px;
    color: #424949;
    text-align: center;
  }

  &__list-field {
    margin-top: 10px;
    // padding: 8px;
    width: 100%;
    height: 100%;
  }

  &__single-custom-field {
    margin: 8px;
    // margin-bottom: 8px;
    // background-color: red;
    // border: 1px solid yellow;
    // width: 100%;
  }
}