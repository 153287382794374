.title {
  padding-left: 0px;
}

.location-assignment {
  display: flex;
  flex-direction: column;
  &__tree-section {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }
  &__data-section {
    width: 100%;
  }
  &__select-button {
    align-self: flex-start;
    height: 40px;
    margin: 10px 0px 20px 0px !important;
    border-radius: 10px;
  }
}

@media (min-width: 600px) {
  .title {
    padding-left: 15px;
  }
  .location-assignment {
    flex-direction: row;
    justify-content: space-around;
    &__tree-section {
      width: 40%;
      display: flex;
      flex-direction: column;
      margin-bottom: 0px;
    }
    &__data-section {
      width: 40%;
    }
    &__select-button {
      align-self: flex-end;
      margin: 0px;
    }
  }
}