.__container-tr {
  display: flex;
  justify-content: space-between; 
  width: 100%;
  padding: 20px;
  align-items: center;
  justify-content: center;
  margin-bottom: 40px;
  @media screen and (max-width: 800px) {
    flex-direction: column;
    margin-bottom: 40px;
  }
}

.__container-tr-left {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  width: 80%;
  @media screen and (max-width: 800px) {
    width: 100%;
  }
}

.__container-tr-right {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 30%;
  @media screen and (max-width: 800px) {
    width: 80%;
    margin-top: 20px;
  }
}

.__container-tr-btn {
  display: flex;
  justify-content: space-around;
}

.__slider {
  height: 250px !important;
  @media screen and (max-width: 800px) {
    height: auto !important;
  }
}